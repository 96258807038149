import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Anchor, Image, NavBar, breakpoints } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'
import { CustomNavbarProps } from '../Layout'

interface UserMenuMobileNavbarProps {
  onLogoClick?: () => void
}

const left = () => (
  <Anchor onClick={() => navigate('../')}>
    <Icon iconId="arrow_left" color="white" size="24" />
  </Anchor>
)

const CenterWrapper = styled.div`
  .img-logo {
    width: auto;
    height: 36px;
  }
  @media (${breakpoints.desktop.min}) {
    .img-logo {
      height: 48px;
    }
  }
`

const center = (onLogoClick: UserMenuMobileNavbarProps['onLogoClick']) => (
  <CenterWrapper>
    <Anchor onClick={() => onLogoClick || null}>
      <Image
        src="https://images.ctfassets.net/16npdkkoi5mj/4EAocVvRjUs3wHYN7PiSDB/f89865bb82569f8a649d1c07fbfbfbd5/CCO_-_BLANCO_-_SVG.svg"
        alt="logo"
        className="img-logo"
        preload={false}
      />
    </Anchor>
  </CenterWrapper>
)

export const UserMenuMobileNavbar = (p: UserMenuMobileNavbarProps) => (props: CustomNavbarProps) => {
  return <NavBar left={left()} center={center(p.onLogoClick)} />
}
