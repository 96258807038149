import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { navigate } from 'gatsby'
import {
  Anchor,
  useResolution,
  TextField,
  Button,
  Error,
  InfoMessage,
  breakpoints,
  resetPasswordConfirm,
  useShoppingCart,
  useAuth,
} from '@ecommerce/shared'
import { Icon } from '../../components/Icon/Icon'
import Layout from '../../components/Layout'
import { PageContainer } from '../../components/PageContainer'
import { PgPageProps } from '../../types/PgPages'
import NavBarStaticPage from '../../components/NavBar/NavBarStaticPage'
import withPageTransition from '../../components/withPageTransition'
import { UserMenuMobileNavbar } from '../../components/NavBar/UserMenuMobileNavbar'
import { sendPageViewEvent } from '../../utils/events'

const cssPrefix = `NewPassword__`

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .${cssPrefix} {
    &email-image {
      margin: 0 auto;
      width: 150px;
      @media (${breakpoints.desktop.min}) {
        padding-right: 30px;
      }
    }
    &top-bar {
      text-align: center;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      &-icon {
        width: 30px;
        cursor: pointer;
      }
      &-title {
        text-align: center;
        font-size: 18px;
        flex-grow: 1;
        font-weight: initial;
        @media (${breakpoints.desktop.min}) {
          padding-right: 30px;
        }
      }
    }
    &container {
      width: 314px;
      display: flex;
      flex-direction: column;
      .${cssPrefix} {
        &form {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-current-password {
            margin-bottom: 30px;
          }
          &-new-password {
            margin-bottom: 20px;
          }
          &-confirm-new-password {
            margin-bottom: 40px;
          }
          &-submit-button {
            width: 250px;
            margin-bottom: 15px;
          }
          &-success-message {
            margin-bottom: 30px;
            font-size: 1.1em;
            text-align: center;
          }
        }
        &status-error {
          display: flex;
          justify-content: center;
          width: 100%;
          &-message {
            width: 250px;
            color: ${(props) => props.theme.colors.error};
            svg {
              fill: ${(props) => props.theme.colors.error};
            }
          }
        }
      }
    }
  }
`

interface FormData {
  newPassword: string
  confirmNewPassword: string
}

const schema = Yup.object().shape({
  newPassword: Yup.string().required('Debes completar este campo').min(8, 'Mínimo 8 caracteres'),
  confirmNewPassword: Yup.string().required('Debes completar este campo').min(8, 'Mínimo 8 caracteres'),
})

const NewPassword = (props: PgPageProps) => {
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Confirmar reinicio de contraseña' })
  }, [])

  const { isDesktop } = useResolution()

  const [authError, setAuthError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [formStep, setFormStep] = useState(1)

  const { register, handleSubmit, errors, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })
  const watchFields = watch()
  const errorMessage = authError?.description
  const isSubmitDisabled = !watchFields.newPassword || !watchFields.confirmNewPassword

  useEffect(() => {
    errors.newPassword = undefined
    errors.confirmNewPassword = undefined
    Object.keys(errors)
  }, [watchFields])

  const onSubmit = async ({ newPassword, confirmNewPassword }: FormData) => {
    if (newPassword !== confirmNewPassword) {
      setAuthError({ code: '400', description: 'Las contraseñas no coinciden' })
      return
    }
    const params = new URLSearchParams(window.location.search)
    const passwordResetToken = params.has('token') && params.get('token')
    const passwordResetId = params.has('prid') && params.get('prid')

    if (!(passwordResetToken && passwordResetId)) {
      setAuthError({ code: '400', description: 'Url inválida' })
      return
    }
    if (isLoading) return
    setIsLoading(true)
    await resetPasswordConfirm({ password: newPassword, passwordResetId, passwordResetToken })
      .then(() => {
        setIsLoading(false)
        setFormStep(2)
      })
      .catch((e) => {
        setAuthError({ code: '400', description: 'Error al confirmar contraseña' })
        setIsLoading(false)
      })
  }

  const CCLogo =
    'https://images.ctfassets.net/16npdkkoi5mj/5DTF4Po0EexHavHJLoeXxz/6d5068dfd1ff02529cd6d2263c476568/Logococamicuenta.svg'

  return (
    <Layout title="Cambiar contraseña" navbar={isDesktop ? NavBarStaticPage : UserMenuMobileNavbar({})}>
      <PageContainer cardMaxWidthDesktop="723px" cardWidthDesktop="723px" cardMinWidthDesktop="723px">
        <PageWrapper>
          <div className={`${cssPrefix}top-bar`}>
            {isDesktop && (
              <Anchor onClick={() => navigate(`/`)} className={`${cssPrefix}top-bar-icon`}>
                <Icon iconId="arrow_left" size="24" />
              </Anchor>
            )}
            <img src={CCLogo} className={`${cssPrefix}email-image`} alt="confirm" />
          </div>
          <div className={`${cssPrefix}container`}>
            {formStep === 1 ? (
              <>
                <h2 className={`${cssPrefix}top-bar-title`}>Crea una nueva contraseña</h2>
                <form className={`${cssPrefix}form`} onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    name="newPassword"
                    data-test="new-password-field"
                    className={`${cssPrefix}form-new-password`}
                    placeholder="Nueva contraseña"
                    type="password"
                    helpMessage="Mínimo 8 caracteres"
                    status={!errors.newPassword ? undefined : 'error'}
                    errorMessage={errors.newPassword?.message}
                    ref={register}
                    disabled={isLoading}
                  />
                  <TextField
                    name="confirmNewPassword"
                    data-test="confirm-new-password-field"
                    className={`${cssPrefix}form-confirm-new-password`}
                    placeholder="Repite la nueva contraseña"
                    type="password"
                    helpMessage="Mínimo 8 caracteres"
                    status={!errors.confirmNewPassword ? undefined : 'error'}
                    errorMessage={errors.confirmNewPassword?.message}
                    ref={register({
                      validate: (value) => value === watch('newPassword') || "Passwords don't match.",
                    })}
                    disabled={isLoading}
                  />
                  <Button
                    isDisabled={isSubmitDisabled}
                    disabled={isLoading || isSubmitDisabled}
                    type="submit"
                    isLoading={isLoading}
                    className={`${cssPrefix}form-submit-button`}
                  >
                    Continuar
                  </Button>
                </form>
                <div className={`${cssPrefix}status-error`}>
                  <InfoMessage
                    className={`${cssPrefix}status-error-message`}
                    isHidden={!errorMessage}
                    message={errorMessage}
                  />
                </div>
              </>
            ) : null}
            {formStep === 2 ? (
              <>
                <div className={`${cssPrefix}form`}>
                  <span className={`${cssPrefix}form-success-message`}>
                    ¡Tu contraseña ha sido restablecida con éxito!
                  </span>
                  <Button className={`${cssPrefix}form-submit-button`} onClick={() => navigate(`/`)}>
                    Ir a inicio
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </PageWrapper>
      </PageContainer>
    </Layout>
  )
}

export default withPageTransition(NewPassword)
